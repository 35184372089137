// types
import type { Enrollment } from '@revolutionprep/types'

// stores
import { useTrialStore } from '@/store/trial'

export function useTrial () {
  /**
 * stores
 * ==================================================================
 */
  const trialStore = useTrialStore()
  const {
    isActiveTrial,
    isExpiredTrial,
    activeTrialDays
  } = storeToRefs(trialStore)

  /**
 * methods
 * ==================================================================
 */
  function hasActiveTrial (enrollments: Array<Enrollment>) {
    const enrollment = enrollments.find(
      enrollment => enrollment?.brand?.name === 'Trial'
    )

    if (enrollment) {
      activeTrialDays.value = _calculateTrialExpires(
        enrollment?.startsAt, enrollment.brand?.forceScheduleByAfterDays
      )

      isActiveTrial.value = activeTrialDays.value > 0
      isExpiredTrial.value = activeTrialDays.value < 1
    } else {
      isActiveTrial.value = false
      isExpiredTrial.value = false
    }
  }

  function _calculateTrialExpires (
    start: any,
    forceScheduleByAfterDays?: number
  ) {
    // We need to uncomment this code when we roll back the changes to calculate the expiration date based on the start date
    // if (start && forceScheduleByAfterDays) {
    //   const startDate = new Date(start)
    //   const today = new Date()

    //   // Add forceScheduleByAfterDays to startDate
    //   const trialExpiresDate = new Date(startDate)
    //   trialExpiresDate.setDate(
    //     trialExpiresDate.getDate() + forceScheduleByAfterDays
    //   )

    //   // Calculate the difference in days between trialExpiresDate and today
    //   const timeDifference = trialExpiresDate.getTime() - today.getTime()
    //   const daysUntilTrialExpires = Math.ceil(
    //     timeDifference / (1000 * 3600 * 24)
    //   )

    //   return daysUntilTrialExpires
    // }

    // return 0

    // Temporary code to calculate the expiration date based on the start date
    if (start && forceScheduleByAfterDays) {
      const startDate = new Date(start)
      const today = new Date()

      // Define the special start date: February 7, 2025, at 00:00 PT
      const specialStartDate = new Date('2025-02-07T08:00:00.000Z') // 00:00 PT → 08:00 UTC

      // Determine expiration days based on the start date in Pacific Time
      const expirationDays = startDate >= specialStartDate ? 30 : 14

      // Determine expiration date based on forceScheduleByAfterDays or default expiration
      const trialExpiresDate = new Date(startDate)
      trialExpiresDate.setDate(trialExpiresDate.getDate() + (expirationDays))

      // Calculate the difference in days between trialExpiresDate and today
      const timeDifference = trialExpiresDate.getTime() - today.getTime()
      const daysUntilTrialExpires = Math.ceil(timeDifference / (1000 * 3600 * 24))

      return daysUntilTrialExpires
    }

    return 0
  }

  return { hasActiveTrial }
}
